import React, { useState } from "react";
import { Element, Link } from "react-scroll";
import ELEARNING from "../../../assets/image/cwc/elearning2.png";
import UNI_CONNECT_1 from "../../../assets/image/cwc/uniconnect1.png";
import UNI_CONNECT_2 from "../../../assets/image/cwc/uniconnect.png";
import UNI_CONNECT_3 from "../../../assets/image/cwc/uniValley.png";

import FEATURE1 from "../../../assets/image/cwc/cwc-Visa-Requirements.png";
import FEATURE2 from "../../../assets/image/cwc/cwc-Visa-Assesment.png";
import FEATURE3 from "../../../assets/image/cwc/cwc-Document-Checklist.png";
import FEATURE4 from "../../../assets/image/cwc/cwc-Counselling.png";
import FEATURE5 from "../../../assets/image/cwc/cwc-Application-Support.png";
import FEATURE6 from "../../../assets/image/cwc/cwc-Guidance-From-Expert.png";

import MOBILELOGO from "../../../assets/image/cwc/mobilelogo.png";
import YOUTUBETHUMB1 from "../../../assets/image/cwc/youtubethumb1.png";
import YOUTUBETHUMB2 from "../../../assets/image/cwc/youtubethumb2.png";
import YOUTUBETHUMB3 from "../../../assets/image/cwc/youtubethumb3.png";
import YOUTUBETHUMB4 from "../../../assets/image/cwc/youtubethumb4.png";
import YOUTUBETHUMB5 from "../../../assets/image/cwc/youtubethumb5.png";

import UNI_REP from "../../../assets/image/cwc/University Represtatives.png";

import CWCPic from "../../../assets/image/cwc/Cafe2.png";

import CWCEvent16NovForm from "./CWCEvent16NovForm";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { Navigation, Thumbs } from "swiper/modules";
import useWindowSize from "../../../Components/hooks/useWindowSize";
import { SlArrowRight } from "react-icons/sl";

const attendSecData = [
  {
    title: "Clarity on Visa Requirements",
    content:
      "Up-to-date information on changing visa regulations, so you know exactly what's needed.",
    image: FEATURE1,
  },
  {
    title: "On-the-Spot Visa Assessment",
    content:
      "A personalized assessment to evaluate your current readiness for the visa process.",
    image: FEATURE2,
  },
  {
    title: "Document Checklist & Preparation",
    content:
      "Detailed guidance to help you gather all essential documents, minimizing delays.",
    image: FEATURE3,
  },
  {
    title: "One-on-One Counseling",
    content:
      "Get answers to your specific questions about studying abroad and navigating visa complexities.",
    image: FEATURE4,
  },
  {
    title: "Step-by-Step Application Support",
    content:
      "Structured support through each stage of the visa process to keep you on track.",
    image: FEATURE5,
  },
  {
    title: "Trusted Guidance from Experts",
    content:
      "Backed by years of experience helping students like you, we’re here to make sure nothing stands in the way of your study abroad journey.",
    image: FEATURE6,
  },
];
const videos = [
  {
    id: 1,
    title: "Careerline's December 16 Recap",
    src: "https://youtube.com/embed/j-tcJm0P_9E",
    thumbnail: YOUTUBETHUMB1,
  },
  {
    id: 2,
    title: " Your Gateway to International Education",
    src: "https://youtube.com/embed/TaMdZeRRDR4",
    thumbnail: YOUTUBETHUMB2,
  },
  {
    id: 3,
    title: "Your Gateway to International Education",
    src: "https://youtube.com/embed/V3qGPvD5GQw",
    thumbnail: YOUTUBETHUMB3,
  },
  {
    id: 4,
    title: "Coffee with Careerline's Dec 16th Recap",
    src: "https://youtube.com/embed/WfTiuiG0vhE",
    thumbnail: YOUTUBETHUMB4,
  },
  {
    id: 5,
    title: "Recap of an Exciting Study Abroad Consultation Event",
    src: "https://www.youtube.com/embed/BHinsNtjg5A?si=7-01emuhmZiTFeU2",
    thumbnail: YOUTUBETHUMB5,
  },
];

// const testimonials = [
//   {
//     name: "Kshitij",
//     state: "Delhi",
//     country: "United Kingdom",
//     testimonial:
//       "My Leverage Edu coach made studying abroad a breeze. From university shortlisting to visa application, they guided me every step of the way.",
//     field: "Data Science",
//     university: UNI_1,
//   },
//   {
//     name: "Samad",
//     state: "Gujarat",
//     country: "United Kingdom",
//     testimonial:
//       "Extremely satisfied with Leverage Edu for my college application process. Deserves a perfect 5/5 rating!",
//     field: "Data Science",
//     university: UNI_1,
//   },
//   {
//     name: "Shubham",
//     state: "Telangana",
//     country: "United States",
//     testimonial:
//       "Smooth process, supportive loan team, highly satisfied with Leverage Edu's loan experience. Great service!",
//     field: "Sciences",
//     university: UNI_1,
//   },
//   {
//     name: "Naveenkumar",
//     state: "Karnataka",
//     country: "United States",
//     testimonial:
//       "I applied to 4 universities and I got offers from 2 universities. Leverage Edu helped me through the entire process to pursue masters. They have separate teams to handle all this.",
//     field: "Data Science",
//     university: UNI_1,
//   },
//   {
//     name: "Shreya",
//     state: "Noida",
//     country: "Germany",
//     testimonial:
//       "Leverage Edu is proactive, detail-oriented, and trustworthy. They made my study abroad dream a breeze. Shoutout to the team for showcasing their excellence!",
//     field: "Management",
//     university: UNI_1,
//   },
//   {
//     name: "Hanna",
//     state: "Maharashtra",
//     country: "Canada",
//     testimonial:
//       "Leverage Edu made it incredibly convenient. Deepa, the consultant, provided excellent guidance. I'm thrilled with the all-in-one support for loans, forex, and accommodation.",
//     field: "Data Science",
//     university: UNI_1,
//   },
// ];

const faqData = [
  {
    question: `What is "Coffee with Careerline"?`,
    answer: `"Coffee with Careerline" is an informal yet informative session designed to answer all your questions about studying abroad. Enjoy a cup of coffee on us while our experts provide personalized advice and guidance.`,
  },
  {
    question: "Who can attend this event?",
    answer:
      "This event is for students, graduates, and professionals interested in studying abroad, as well as their parents who want to learn more about overseas education opportunities.",
  },
  {
    question: "Do I need to register to attend?",
    answer:
      "Yes, registration is mandatory to reserve your free spot. Fill out the form with your details to secure your place.",
  },
  {
    question: "What countries are covered in this session?",
    answer:
      "Our experts can guide you on studying in the USA, UK, Canada, Australia, and New Zealand. Let us know your preference while registering.",
  },
  {
    question: "What courses or exams will be discussed?",
    answer:
      "We’ll cover information about PTE, GRE, SAT, DUOLINGO, IELTS AC, and IELTS GT exams, along with guidance on selecting the right course for your future.",
  },
  {
    question: "Can I bring a friend or family member with me?",
    answer:
      "Absolutely! Great conversations are even better when shared. Let us know in advance so we can prepare accordingly.",
  },
  {
    question:
      "Will there be mentors or university representatives at the event?",
    answer:
      "Our experienced mentors will guide you through the study-abroad process. While this event doesn’t feature university representatives, we’ll share direct insights about universities and courses.",
  },
  {
    question: "What should I bring to the event?",
    answer:
      "Bring your curiosity, your questions, and perhaps a notebook to jot down important tips. No formal documents are required.",
  },
  {
    question: "What kind of information will I gain from this session?",
    answer:
      "You’ll learn about university selection, scholarships, visa processes, test preparation, and much more—tailored specifically to your profile and goals.",
  },
  {
    question: "What if I can’t attend in person?",
    answer:
      "Don't worry! We organize CWC sessions often and also you discuss further options to get assistance from our expert team members!",
  },
];

const CWCEventPage = () => {
  const [swiperRef, setSwiperRef] = useState(null);
  const [activeThumb, setActiveThumb] = useState(0);
  const [currentData, setCurrentData] = useState(videos[0]);
  const { width } = useWindowSize();

  const handleSlideChange = (swiper) => {
    if (
      (swiper?.realIndex === undefined || swiper.realIndex === null,
      swiper.realIndex === 0)
    ) {
      setCurrentData(videos[videos?.length - 1]);
    } else {
      setCurrentData(videos[swiper.realIndex - 1]);
    }
  };

  return (
    <div>
      <div className="cwc3-event-hero-section">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-6">
              <h1 className="mb-0">
                Join Us This Saturday to Resolve All Your{" "}
                <span
                  style={{
                    color: "#275aa6",
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  Visa Doubts
                </span>
              </h1>
              <p className="mt-2">
                Get on-the-spot assessment, visa clarity, and personalized
                guidance—all at no cost
              </p>
              <div className="event-btn-wrapper">
                <Link
                  activeClass="active"
                  to="cwcregistration-form"
                  offset={-120}
                  duration={300}
                  className="event-banner-btn"
                >
                  Reserve Your Free Spot
                </Link>
              </div>
            </div>
            <div className="col-lg-6 text-center text-lg-end mt-4 mt-lg-0">
              <img src={ELEARNING} alt="Coffee with Careerline" height="100%" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="cwc3-event-hero-section"
        style={{ background: "#d9e8ff" }}
      >
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 mb-5">
              <h1 className="mb-0 text-center">
                <span
                  style={{
                    color: "#275aa6",
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  Impact
                </span>{" "}
                that counts
              </h1>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={UNI_CONNECT_2}
                className="counts-image"
                alt="Coffee with Careerline"
              />
              <h1 className="mb-0">850+</h1>
              <p className="counts-para">University Partners</p>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={UNI_CONNECT_3}
                className="counts-image"
                alt="Coffee with Careerline"
              />
              <h1 className="mb-0">45,000+</h1>
              <p className="counts-para">Success Stories</p>
            </div>
            <div className="col-sm-4 text-center">
              <img
                src={UNI_CONNECT_1}
                className="counts-image"
                alt="Coffee with Careerline"
              />
              <h1 className="mb-0">₹ 10,00,000+</h1>
              <p className="counts-para">Worth Of Scholarship</p>
            </div>
          </div>
        </div>
      </div>
      <div className="cwc3-event-section-3">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 mb-5">
              <h1 className="text-center mb-0">
                Careerline{" "}
                <span
                  style={{
                    color: "#275aa6",
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  Advantage
                </span>
              </h1>
            </div>
            {attendSecData?.map((item) => (
              <div className="col-lg-6 d-flex gap-3 mb-5">
                <img
                  src={item?.image}
                  alt="attend image 1"
                  style={{ background: "#275aa6", borderRadius: "15px" }}
                />
                <div>
                  <h3>{item?.title}</h3>
                  <p>{item?.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="cwc3-event-section-6">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 mb-5">
              <h1 className="text-center mb-0">
                45,000+{" "}
                <span
                  style={{
                    color: "rgb(32, 161, 105)",
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  Success Stories
                </span>
              </h1>
              <p className="text-center">From Dreamers to Achievers</p>
            </div>
          </div>
          <div className="row testimonial-sec">
            {testimonials?.map((testimonial) => (
              <div className="col-md-6 col-lg-4 story-card p-2">
                <div
                  className="card-wrapper bg-white p-3"
                  style={{ borderRadius: 15, height: "100%" }}
                >
                  <div className="d-flex gap-2 mb-4">
                    <img
                      src={UNI_CONNECT_1}
                      style={{ height: 60, width: 60, borderRadius: "50%" }}
                    />
                    <div>
                      <p className="mb-1 fw-600">{testimonial?.name}</p>
                      <span className="story-country">
                        {testimonial?.state} → {testimonial?.country}
                      </span>
                    </div>
                  </div>
                  <p className="mb-4">{testimonial?.testimonial}</p>
                  <div className="d-flex justify-content-between">
                    <div>
                      <span
                        style={{
                          display: "block",
                          padding: "8px 20px",
                          background: "#d1f9e8",
                          color: "#275aa6",
                          borderRadius: "17px",
                        }}
                      >
                        {testimonial?.field}
                      </span>
                    </div>
                    <img
                      src={testimonial?.university}
                      alt="university logo"
                      style={{ height: 50 }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="cwc3-event-section-4">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <h1 className=" mb-0">
                What our Favourite{" "}
                <span
                  style={{
                    color: "#d9e8ff",
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  Student Voices
                </span>{" "}
                have to Say
              </h1>
              <p className="mt-2">
                Discover how Careerline has transformed the journeys of students
                aspiring to study abroad. From expert guidance to personalized
                support, our students share their success stories and the
                invaluable benefits they’ve gained.
              </p>
              <p>
                Watch their inspiring testimonials and see how Careerline can
                help you achieve your dream of studying overseas. Don’t miss
                this chance—watch the videos and secure your free spot today!
              </p>
              <div class="d-flex">
                <button class="influencerButton">
                  <img
                    height="52"
                    alt=""
                    style={{
                      marginLeft: "10px",
                      marginRight: "16px",
                      height: "52px",
                      width: "50px",
                      objectFit: "contain",
                      borderRadius: "50%",
                      background: "#ffffff26",
                    }}
                    src={currentData?.thumbnail}
                  />
                  <div class="influencerDetails">
                    <p className="m-0">{currentData?.title}</p>
                  </div>
                </button>
                <button
                  class="nextButton"
                  onClick={() => swiperRef?.slideNext()}
                >
                  <SlArrowRight style={{ fontSize: 25 }} />
                </button>
              </div>
            </div>
            <div className="col-lg-8 d-flex justify-content-end align-items-center gap-3">
              <div className="mobile-wrapper">
                <img
                  className="mobile-mockup"
                  src={MOBILELOGO}
                  alt=""
                  style={{ filter: "invert(1)" }}
                />
                {/* <video
                  controls
                  autoPlay
                  muted
                  src={currentData?.src}
                  alt="trusted voices 1"
                /> */}
                <iframe
                  src={currentData?.src}
                  title="YouTube Shorts Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <Swiper
                modules={[Navigation, Thumbs]}
                spaceBetween={10}
                slidesPerView={width < 405 ? 1 : 2}
                // navigation
                loop
                onSwiper={(swiper) => setSwiperRef(swiper)}
                onSlideChange={(swiper) => {
                  handleSlideChange(swiper);
                  // setCurrentData(videos[swiper.realIndex]);
                }}
                thumbs={{ swiper: activeThumb }}
              >
                {videos.map((video) => {
                  return (
                    <SwiperSlide key={video.id}>
                      <img
                        src={video?.thumbnail}
                        alt="Influencers"
                        className="swiper-thumbnails"
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      {/* ************************************** */}
      <div className="cwc-event-goodnews-section cwc-nov-event pt-5 ">
        <div className="container">
          <div className="row">
            {/* <div className="col-12 mb-3">
              <h1 className="mb-0">
                THE GOOD NEWS:{" "}
                <span
                  style={{
                    fontSize: "clamp(14px, 2vw, 30px)",
                    fontWeight: 300,
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  We’ve got all the answers!
                </span>
              </h1>
            </div> */}
            <div className="col-lg-7">
              {/* <p className="goodnews-subline">
                Join us for a{" "}
                <b style={{ color: "#FFE145" }}>casual coffee session</b> where
                we answer your questions and{" "}
                <i style={{ color: "#FFE145" }}>guide you step-by-step</i>. And
                did we mention the coffee is on us!
              </p> */}
              <table border={0} className="w-100 w-lg-75 ">
                <tr>
                  <td width={"30%"}>Date &nbsp;&nbsp;&nbsp; :</td>
                  <td>30/11/2024</td>
                </tr>
                <tr>
                  <td>Time &nbsp;&nbsp;&nbsp;:</td>
                  <td>09:00 AM to 11:30 AM</td>
                </tr>
                <tr>
                  <td>Venue &nbsp;:</td>
                  <td>
                    <a
                      href="https://maps.app.goo.gl/YS56iHYubNsfQ5P68?g_st=ic"
                      target="_blank"
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      {/* Shambhu’s Cafe{" "}
                      <FaJava style={{ verticalAlign: "baseline" }} /> */}
                      Shambhu's Cafe, A - 307, Navratna Corporate Park Iscon,
                      Ambli Rd, Ashok Vatika, Ahmedabad, Gujarat 380058
                    </a>
                  </td>
                </tr>
              </table>
              <div style={{ padding: "50px 0" }}>
                <Link
                  activeClass="active"
                  to="cwcregistration-form"
                  offset={-120}
                  duration={300}
                  className="event-form-navigation-btn"
                >
                  Reserve My Free Spot & Coffee Now!
                </Link>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={CWCPic} alt="Coffee with Careerline" height="100%" />
            </div>
          </div>
        </div>
      </div>

      {/* *********************************** */}
      <div className="cwc3-event-section-5">
        <div className="container pt-5 pb-5">
          <div className="row">
            <Element className="col-lg-12" name="cwcregistration-form">
              <CWCEvent16NovForm />
            </Element>
          </div>
        </div>
      </div>
      <div className="cwc3-event-section-7">
        <div className="container pt-5 pb-5">
          <div className="row">
            <div className="col-12 mb-5">
              <h1 className="text-center mb-0">
                <span
                  style={{
                    color: "#fff",
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  FAQs
                </span>
              </h1>
            </div>
          </div>
          <div className="row testimonial-sec">
            <div className="faqs-list">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {faqData.map((item, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-headingOne${index}`}
                        aria-expanded={index === 0 ? "true" : "false"}
                        aria-controls="flush-collapseOne"
                      >
                        {item?.question}
                      </button>
                    </h2>
                    <div
                      id={`flush-headingOne${index}`}
                      className={`accordion-collapse collapse  ${
                        index === 0 ? "show" : ""
                      }`}
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body">{item?.answer}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CWCEventPage;
